<template>
  <section class="app-main" style="height: 100%">
    <transition :name="transitionName">
      <router-view
        :class="transitionName != 'none' ? 'child-view' : ''"
        :key="key"
      />
    </transition>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Layout",
  computed: {
    key() {
      return this.$route.path;
    },
    ...mapState({
      direction: (state) => state.settings.direction,
    }),
  },
  watch: {
    $route() {
      if (this.direction === "none") {
        this.transitionName = "none";
      } else if (this.direction === "forward") {
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
  },
  data() {
    return {
      transitionName: "",
    };
  },
};
</script>

<style scoped>
.child-view {
  position: absolute;
  width: 100%;
  transition: all 0.8s cubic-bezier(0.55, 0, 0.1, 1);
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(50px, 0);
  transform: translate(50px, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-50px, 0);
  transform: translate(-50px, 0);
}
</style>