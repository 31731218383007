/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const router = {
  path: '/huayang',
  component: Layout,
  children: [
    {
      path: '',
      name: 'index',
      meta: {
        title: '中介超市',
      },
      component: () => import('@/views/huayang/index.vue'),
    },
    {
      path: '/huayang/interaction',
      name: 'interaction',
      meta: {
        title: '区企互动',
      },
      component: () => import('@/views/huayang/interaction.vue'),
    },
    {
      path: '/huayang/classroom',
      name: 'classroom',
      meta: {
        title: '知识大讲堂',
      },
      component: () => import('@/views/huayang/classroom.vue'),
    },
    {
      path: '/huayang/classroom/particulars/:Id',
      name: 'particulars',
      meta: {
        title: '知识大讲堂',
      },
      component: () => import('@/views/huayang/particulars.vue'),
    },
    {
      path: '/huayang/huayang/company/:Id',
      name: 'company',
      meta: {
        title: '企业详情',
      },
      component: () => import('@/views/huayang/company.vue'),
    },
    {
      path: '/huayang/firmlogin',
      name: 'firmlogin',
      meta: {
        title: '企业登录',
      },
      component: () => import('@/views/huayang/firmlogin.vue'),
    },
    {
      path: '/huayang/lianluo',
      name: 'lianluo',
      meta: {
        title: '联系我们',
      },
      component: () => import('@/views/huayang/lianluo.vue'),
    },
    //  {
    //     path: '/huayang/serve',
    //     name: 'serve',
    //     meta: {
    //         title: "资源看板"
    //     },
    //     component: () =>
    //         import ('@/views/huayang/serve.vue')
    // },
    {
      path: '/huayang/comment',
      name: 'comment',
      meta: {
        title: '服务评价',
      },
      component: () => import('@/views/huayang/comment.vue'),
    },
    {
      path: '/huayang/firm',
      name: 'firm',
      meta: {
        title: '企业端',
      },
      component: () => import('@/views/huayang/firm.vue'),
    },
    {
      path: '/huayang/resource',
      name: 'resource',
      meta: {
        title: '发布资源清单',
      },
      component: () => import('@/views/huayang/resource.vue'),
    },
    // {
    //     path: '/huayang/interaction/suggest',
    //     name: 'suggest',
    //     meta: {
    //         title: "区企互动记录"
    //     },
    //     component: () =>
    //         import ('@/views/huayang/suggest.vue')
    // },
    {
      path: '/home',
      name: 'home',
      meta: {
        title: '首页',
      },
      component: () => import('@/views/huayang/home.vue'),
    },
  ],
}

export default router
